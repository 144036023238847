export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '£';
  }
};

export function createArrayDeepCopy<T>(data: Array<T>): Array<T> {
  return data.map((item) => ({ ...item }));
}

export const availableAutofillDomain = [
  'lever.co',
  'workable.com',
  'greenhouse.io',
  'teamtailor.com',
  'bamboohr.com',
  'workday.com',
  'myworkdayjobs.com',
  'jobvite.com',
  'smartrecruiters.com',
  'recruitee.com',
];

export function isDataAvailable(data?: string | string[]): boolean {
  return Array.isArray(data)
    ? data.filter((item) => !!item?.trim()).length > 0
    : !!data?.trim();
}

export function sendTokenToExtension(email: string, token: string): void {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    chrome.runtime.sendMessage(
      process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID || '',
      { action: 'storeEmailAndToken', email, token },
      (response) => {
        if (response?.success) {
          window.close();
        } else {
          console.error(
            'Failed to store token in the extension',
            response?.error
          );
          location.reload();
        }
      }
    );
  } else {
    console.error('Chrome runtime is not available');
  }
}

export function logoutExtension(): void {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    chrome.runtime.sendMessage(
      process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID || '',
      { action: 'removeEmailAndToken' },
      (response) => {
        if (!response?.success) {
          console.error(
            'Failed to logout token in the extension',
            response?.error
          );
          location.reload();
        }
      }
    );
  } else {
    console.error('Chrome runtime is not available');
  }
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// Custom Scrolling using cubic Bézier curve
// Cubic Bezier formula
function cubicBezier(
  t: number,
  p0: number,
  p1: number,
  p2: number,
  p3: number
) {
  return (
    Math.pow(1 - t, 3) * p0 +
    3 * Math.pow(1 - t, 2) * t * p1 +
    3 * (1 - t) * Math.pow(t, 2) * p2 +
    Math.pow(t, 3) * p3
  );
}

function solveCubicBezier(
  x: number,
  p0: number,
  p1: number,
  p2: number,
  p3: number
) {
  // Approximate 't' for a given 'x' using binary search
  let tMin = 0;
  let tMax = 1;
  let t = 0.5;

  while (tMax - tMin > 1e-5) {
    const xAtT = cubicBezier(t, p0, p1, p2, p3);
    if (xAtT < x) {
      tMin = t;
    } else {
      tMax = t;
    }
    t = (tMin + tMax) / 2;
  }

  return t;
}

// all parameters should be between 0 and 1
function cubicBezierDistance(
  time: number,
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number
) {
  const p0 = 0;
  const p3 = 1;

  // Solve for 't' where the X (time) maps to the desired position
  const t = solveCubicBezier(time, p0, p1x, p2x, p3);

  // Compute the distance (Y) using 't'
  const distance = cubicBezier(t, p0, p1y, p2y, p3);
  return distance;
}

export function smoothScrollToPosition(
  element: HTMLElement,
  scrollX: boolean,
  duration: number,
  targetPosition: number,
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number
) {
  const startPosition = element.scrollLeft;
  const distance = targetPosition - startPosition;
  let startTime: null | number = null;

  function animation(currentTime: number) {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const time = timeElapsed / duration;
    const run = cubicBezierDistance(time, p1x, p1y, p2x, p2y);

    if (scrollX) {
      element.scrollTo(Math.floor(run * distance + startPosition), 0);
    } else element.scrollTo(0, Math.floor(run * distance + startPosition));

    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  requestAnimationFrame(animation);
}
