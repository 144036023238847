import { ParsedUrlQuery } from 'querystring';
import { useMemo } from 'react';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import retry from 'async-retry';

import { Community } from 'components/community-page';
import { Page } from 'components/layouts';
import { getLayout } from 'components/layouts/Header';
import { StudentSocietiesResponse, StudentSocietyResponse, TStudentSociety } from 'lib/models/student-society';
import { CommunityProvider } from 'lib/providers/CommunityProvider';
import { LOCALE_PAGES, fetchTranslationFiles } from 'lib/services/page';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { pageBuilderInstance } from 'lib/utils/axios';
import { API_ROUTES } from 'lib/api-routes';
import { asyncRetryParams } from 'lib/consts';
import { OpportunitiesResponse } from 'lib/models/opportunity';
import {
  PaginatedDocumentAttachmentsResponse,
  PaginatedMediaAttachmentsResponse,
  PaginatedPostsResponse,
  TDocumentPost,
  TMediaPost,
  TPost,
} from 'lib/models/discussion';
import { OrganizationEventsProvider } from 'lib/providers/OrganizationEventsProvider';
import { getCommunityBreadcrumbSchema } from 'utils/seo';
import { getCommunityMetaDescription } from 'utils/community';

import styles from './CommunityPage.module.scss';

type CommunityProps = {
  community: TStudentSociety;
  canonicalURL?: string;
  initialPublishedEventsResponse: OpportunitiesResponse;
  initialPosts: Array<TPost>;
  initialMediaAttachments: TMediaPost['media'];
  initialDocumentAttachments: TDocumentPost['documents'];
  pastEventsInitialCount: number;
};

const CommunityPage: NextPage<CommunityProps> = ({
  community,
  canonicalURL,
  initialPosts,
  initialMediaAttachments,
  initialDocumentAttachments,
  initialPublishedEventsResponse,
  pastEventsInitialCount,
}) => {
  const { name, meta_tags } = community;
  const pageTitle = `${name} | Huzzle`;
  const metaDescription = getCommunityMetaDescription(community);
  const keywords = useMemo(() => {
    const tags = parseArrayResponse(meta_tags);
    if (tags.length === 0) return null;
    return tags.map((tag) => tag.value).join(', ');
  }, [meta_tags]);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        {canonicalURL && <link rel="canonical" href={canonicalURL} />}
        {keywords && <meta name="keywords" content={keywords} />}
        <script
          id="communityBreadcrumbSchema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: getCommunityBreadcrumbSchema({ community }),
          }}
        />
      </Head>
      <Page className={styles.page}>
        <CommunityProvider community={community}>
          <OrganizationEventsProvider
            organization={community}
            initialPublishedEventsResponse={initialPublishedEventsResponse}
            pastEventsInitialCount={pastEventsInitialCount}
            perPageCount={5}
          >
            <Community
              initialPosts={initialPosts}
              initialMediaAttachments={initialMediaAttachments}
              initialDocumentAttachments={initialDocumentAttachments}
            />
          </OrganizationEventsProvider>
        </CommunityProvider>
      </Page>
    </>
  );
};

// @ts-ignore
CommunityPage.getLayout = getLayout;

export default CommunityPage;

export const getStaticPaths: GetStaticPaths = async () => {
  if (process.env.SKIP_BUILD_STATIC_GENERATION) {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }

  const { data } = await pageBuilderInstance<StudentSocietiesResponse>(`${API_ROUTES.STUDENT_SOCIETIES}/basic_index?per_page=100000`);
  const communities = parseArrayResponse(data);
  const paths = communities.map(({ slug }) => {
    return {
      params: { slug },
    };
  });
  return { paths, fallback: 'blocking' };
};

interface Params extends ParsedUrlQuery {
  slug: string;
}

export const getStaticProps: GetStaticProps<CommunityProps, Params> = async (context) => {
  let slug = '';
  let propsData;

  try {
    slug = context.params?.slug as string;
    const pathname = `${API_ROUTES.STUDENT_SOCIETIES}/${slug}`;
    const societyPublishedEventsPathname = `${API_ROUTES.STUDENT_SOCIETIES}/${slug}/touchpoints?touchpointable_type=Event&with_details=true&page=1&per_page=5`;
    const societyPastEventsPathname = `${API_ROUTES.STUDENT_SOCIETIES}/${slug}/touchpoints?touchpointable_type=Event&past=true&per_page=0`;
    const postsPathname = `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(':student_society_id', slug)}?page=1&per_page=100000`;
    const mediaPath = `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(':student_society_id', slug)}/attachments?type=MediaPost&page=1&per_page=100000`;
    const documentsPath = `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(
      ':student_society_id',
      slug
    )}/attachments?type=DocumentPost&page=1&per_page=100000`;
    propsData = await retry(async () => {
      const societyPromise = pageBuilderInstance<StudentSocietyResponse>(pathname);
      const publishedEventsPromise = pageBuilderInstance<OpportunitiesResponse>(societyPublishedEventsPathname);
      const pastEventsPromise = pageBuilderInstance<OpportunitiesResponse>(societyPastEventsPathname);
      const postsPromise = pageBuilderInstance<PaginatedPostsResponse>(postsPathname);
      const mediaAttachmentsPromise = pageBuilderInstance<PaginatedMediaAttachmentsResponse>(mediaPath);
      const documentAttachmentsPromise = pageBuilderInstance<PaginatedDocumentAttachmentsResponse>(documentsPath);
      const [
        { data: societyResponse },
        { data: publishedEventsResponse },
        { data: pastEventsResponse },
        {
          data: { data: initialPosts },
        },
        {
          data: { data: initialMediaAttachments },
        },
        {
          data: { data: initialDocumentAttachments },
        },
      ] = await Promise.all([
        societyPromise,
        publishedEventsPromise,
        pastEventsPromise,
        postsPromise,
        mediaAttachmentsPromise,
        documentAttachmentsPromise,
      ]);
      const community = parseResponse(societyResponse);
      const initialPublishedEventsResponse = publishedEventsResponse;
      const pastEventsInitialCount = pastEventsResponse.meta.total || 0;
      const canonicalURL = `${process.env.NEXT_PUBLIC_SITE_URL}/societies/${community.slug}`;
      return {
        community,
        canonicalURL,
        initialPublishedEventsResponse,
        pastEventsInitialCount,
        initialPosts,
        initialMediaAttachments,
        initialDocumentAttachments,
      };
    }, asyncRetryParams);
  } catch (error) {
    console.error(`Community page not found - ${slug}: ${error}`);
    return { notFound: true };
  }

  return {
    props: {
      ...propsData,
      ...(await fetchTranslationFiles({
        locale: context.locale,
        pages: [LOCALE_PAGES.COMMUNITY, LOCALE_PAGES.DISCUSSIONS],
      })),
    },
    revalidate: 3600,
  };
};
